import * as React from "react"

import LayoutNewsletter from "../components/LayoutNewsletter"
import HeroImageSentiment from "../components/LuxurySentimentPage/HeroImageSentiment"
import NS from "../components/ContactForm/NewsletterSignUp"
import { Helmet } from "react-helmet";


function NSU({ data }) {
  return (
    <LayoutNewsletter>
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="Sign Up to ŁUXE Newsletter" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/32bao1hRboczuAK9j6GUZG/9f2be68b8dc53a23b6f1cbce567f029a/og_Newsletter_4x.png" />
        <meta property="og:description" content="Sign up to the ŁUXE Newsletter. Insiders Opinion on Luxury Digital Transformation." />
        <meta property="og:url" content="https://thefreshkid.com/newsletter-signup/" />
      </Helmet>
    {/* <HeroImageSentiment /> */}
      <NS />
    </LayoutNewsletter>
  )
}

export default NSU