import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/NewsletterSignUp.scss";
import { Helmet } from "react-helmet";

export default function NS() {

  const [occupation, setOccupation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [isEmailInputEnabled, setIsEmailInputEnabled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  const handleOccupationChange = (e) => {
    setOccupation(e.target.value);
    setErrorMessage(''); // Clear error message when a radio button is selected
  };

  const handleEmailInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage(''); // Clear the error message when the user starts typing a different email
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('MERGE0');
    console.log("Selected Radio Button Value: ", selectedRadioValue);
    console.log("Email: ", email);
  
    const emailInput = document.querySelector('input[type="email"]');
    const enteredEmail = emailInput.value.trim().toLowerCase();
  
    const disallowedDomains = ['@gmail', '@yahoo', '@icloud', '@aol', '@mac', '@hotmail', '@outlook', '@proton', '@protonmail', '@zohocorp', '@gmx', '@yandex', '@hubspot', '@neo', '@thunder'];
  
    const containsDisallowedDomain = disallowedDomains.some(domain => enteredEmail.includes(domain));
  
    if (!occupation) {
      setErrorMessage('Please select an occupation');
      return;
    } else if (containsDisallowedDomain) {
        setErrorMessage('Please use your business or educational email');
      // alert("Only business emails are allowed");
      console.error('Disallowed email domain used:', enteredEmail);
      return;
    }
  
    // If validation passes, proceed with form submission
    setErrorMessage('');
    console.log('Form submitted successfully!');
    e.target.submit(); // Manually trigger form submission
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

//   const labelStyle = windowWidth <= 800 ? { paddingLeft: '0' } : { paddingLeft: '62px' };
const labelStyle =
  windowWidth <= 800
    ? { paddingLeft: '0' }
    : windowWidth <= 1000
    ? { paddingLeft: '2.5px' }
    : windowWidth <= 1250
    ? { paddingLeft: '3px' }
    : windowWidth <= 1536
    ? { paddingLeft: '4px' }
    : windowWidth <= 1700
    ? { paddingLeft: '4px' }
    : windowWidth <= 2000
    ? { paddingLeft: '4px' }
    : { paddingLeft: '0px' };

  
  
  return (
    <>
    {/* <Helmet>
        <title>Contact The Fresh Kid</title>
        <meta name="description" content="The Fresh Kid - Luxury data Engineer and Analyst - Make an Enquiry" />
        <meta name="keywords" content="luxury data engineer, luxury data analyst" />
        <h1>Contact Me</h1>
        <h2>Leave me a message and I will respond in a timely fashion</h2>
    </Helmet> */}
    <div className="contentFeatureNS">
    <div className="contentContainerNS">
        <div className="formNS">
        <form action="https://thefreshkid.us8.list-manage.com/subscribe/post" method="POST" target="_blank" onSubmit={handleSubmit}>
              <input type="hidden" name="u" value="8abd2b2ed117ea16fd4a66f6d" />
              <input type="hidden" name="id" value="924bba4fbd" />
                <p className="formTitleNS">Sign Up to ŁUXE Newsletter</p>
                <p className="formSubTitleNS">Insiders Opinion on Luxury Digital Transformation.<br></br>Technical Guides for the Luxury C-Suite and Investors in your inbox once a month.</p>

                <div className="title-sectionNS">
                    <label className="TitleLabelNS" htmlFor="title">*Title: </label>
                    <br></br>
                    <label className="TitleLabelNS" htmlFor="title">Select... </label>
                    <select name="MMERGE4" id="title" required>
                    <option value=""></option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    </select>
                </div>
                <br></br>

                <div className="NSFirstName">
                    <label className="FirstNameLabel" htmlFor="firstname">*First Name:</label>
                    <input type="text" name="FNAME" required/>
                </div>
                <br></br>
                <div className="NSLastName">
                    <label className="LastNameLabel" htmlFor="lastname">*Last Name:</label>
                    <input type="text" name="LNAME" required/>
                </div>
                <br></br>
                <div className="NSBusinessName">
                    <label className="BusinessNameLabel" htmlFor="businessname">*Business or Educational Institute Name:</label>
                    <input type="text" name="MMERGE5" required/>
                </div>
                <br></br>
                <div className="NSBusinessEmail">
                    <label className="BusinessEmailLabel" htmlFor="businessemail">*Business or Educational Institute Email:</label>
                    <input type="email" name="MERGE0" onChange={handleEmailInputChange} required></input>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
                <br></br>

                <div className="NSOccupation">
                    <label className="NSOccuupationLabel" htmlFor="occupation"><em>*Please select your occupation</em></label>
                    <br /><br />
                    <div className="NSradio-labels1">
                        <div className="radio-row1">
                            <label classname= "NSradio1" htmlFor="luxuryinvestor">
                                <input
                                type="radio"
                                name="MMERGE3"
                                id="luxuryinvestor"
                                value="Luxury Investor"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="NSspacers">&nbsp;&nbsp;</span>Luxury Investor
                            </label>

                            <label classname= "NSradio2" htmlFor="luxuryleader">
                                <input
                                type="radio"
                                name="MMERGE3"
                                id="luxuryleader"
                                value="Luxury Leader"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="NSspacers">&nbsp;&nbsp;</span>Luxury Leader
                            </label>
                        </div>
                        <br></br>

                        
                        <div className="radio-row2">
                            <label classname= "NSradio4" htmlFor="luxurystudent">
                                <input
                                type="radio"
                                name="MMERGE3"
                                id="luxurystudent"
                                value="Luxury Student"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="NSspacers">&nbsp;&nbsp;</span>Luxury Student
                            </label>

                            <label classname= "NSradio6" htmlFor="other" style={labelStyle} >
                                <input
                                type="radio"
                                name="MMERGE3"
                                id="other"
                                value="Other"
                                onChange={handleOccupationChange}
                                required
            
                                />
                                <span class="NSspacers">&nbsp;&nbsp;</span>Other
                            </label>
                        </div>

                        <br></br>
                    </div>
                </div>
                <br></br>
                <div className="NSOther">
                    <label className="NSOther1Label" htmlFor="other"><em>*Required Information</em></label>
                    <br></br>
                    <label className="NSOther2Label" htmlFor="other">By subscribing, you agree to our <a href="/PrivacyPolicy" class="privacy-link">Privacy & Cookies Policy</a></label>
                    <br></br>
                    {/* <label className="NSOther3Label" htmlFor="other">Please consult our <a href="/PrivacyPolicy" class="privacy-link">Privacy Policy</a> for more information</label> */}
                </div>
                <br></br>
                <div className="NSSubmit">
                    <input type="submit" value="Sign Up to Luxe" />
                </div>
                <br></br>
                <br></br>
            </form>
        </div>
    </div>
  </div>
  </>
  )
}


